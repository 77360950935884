import { ApolloCache, Resolvers as BaseResolvers } from "@apollo/client";
import { getUser } from "@services/auth";
import { apiURL } from "@helpers/configs";

export type ResolverFn = (
  parent: any,
  args: any,
  cache: { cache: ApolloCache<any> }
) => any;

export interface ResolverMap {
  [field: string]: ResolverFn;
}

export interface Resolvers extends BaseResolvers {
  Mutation: ResolverMap;
  Query: ResolverMap;
}

export type CreateResolversFn = () => Resolvers;

export const createResolvers: CreateResolversFn = () => {
  return {
    Query: {
      insights: async (
        _: any,
        {
          detail_id,
          name,
          status,
          email,
          contact_no,
          contact_no2,
          contact_no3,
          gender,
          title,
          add_1,
          add_2,
          add_3,
          add_4,
          add_5,
          city,
          postcode,
          state,
          country,
          old_ic,
          new_ic,
          other_ic,
          bus_reg,
          batch,
          c_date,
          c_by,
          u_date,
          u_by,
          emp_name,
          monthly_income,
          race,
          occupation,
          blood_type,
          skip,
          pageSize,
        }: {
          name: string;
          mobile_no: string;
          new_ic: string;
          detail_id: string;
          name: string;
          status: string;
          email: string;
          contact_no: string;
          contact_no2: string;
          contact_no3: string;
          gender: string;
          title: string;
          add_1: string;
          add_2: string;
          add_3: string;
          add_4: string;
          add_5: string;
          city: string;
          postcode: string;
          state: string;
          country: string;
          old_ic: string;
          new_ic: string;
          other_ic: string;
          bus_reg: string;
          batch: string;
          c_date: string;
          c_by: string;
          u_date: string;
          u_by: string;
          emp_name: string;
          monthly_income: string;
          race: string;
          occupation: string;
          blood_type: string;
          skip: number;
          pageSize: number;
        }
      ) => {
        try {
          const token = getUser().token;

          const response = await fetch(`${apiURL}/insights`, {
            method: "POST",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json; charset=UTF-8",
              authorization: `Bearer ${token}`,
            },
            body: JSON.stringify({
              detail_id,
              name,
              status,
              email,
              contact_no,
              contact_no2,
              contact_no3,
              gender,
              title,
              add_1,
              add_2,
              add_3,
              add_4,
              add_5,
              city,
              postcode,
              state,
              country,
              old_ic,
              new_ic,
              other_ic,
              bus_reg,
              batch,
              c_date,
              c_by,
              u_date,
              u_by,
              emp_name,
              monthly_income,
              race,
              occupation,
              blood_type,
              skip,
              pageSize,
            }),
          });
          const data = await response.json();

          return {
            data,
            __typename: "Insights",
          };
        } catch (err) {
          console.log(err);
        }
      },
    },
  };
};
