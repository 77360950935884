import {
  ApolloClient,
  ApolloLink,
  HttpLink,
  InMemoryCache,
} from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import fetch from "isomorphic-fetch";
import { grapqhURL } from "@helpers/configs";

import { getUser } from "@services/auth";

import { createResolvers } from "./resolvers";

const token = getUser().token;

export const apollo = new ApolloClient({
  link: ApolloLink.from([
    setContext((_, { headers, ...context }) => {
      return {
        headers: {
          ...headers,
          ...(token ? { authorization: `Bearer ${token}` } : {}),
        },
        ...context,
      };
    }),
    new HttpLink({
      uri: `${grapqhURL}`,
      fetch,
    }),
  ]),
  cache: new InMemoryCache(),
  resolvers: createResolvers(),
});
